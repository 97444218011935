@import "~react-image-gallery/styles/css/image-gallery.css";

$font-size-huge: 45px;
$font-size-big: 24px;
$font-size-medium: 18px;
$font-size-normal: 14px;
$font-size-small: 12px;
$font-size-tiny: 10px;

$nav-height: 50px;
$page-min-height: calc(100vh - $nav-height);

* {
  transition: all 0.2s;
}

.no-transition, .no-transition-at-all, .no-transition-at-all * {
  transition: none !important;
}

small a {
  font-size: inherit;
}

.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

.bb1 {
  border-bottom: 1px solid #ccc;
}

.mt-8 {
  margin-top: 50px;
}

.pt-8 {
  padding-top: 50px;
}

.mt-10{
  margin-top:70px;
}

.pt-10{
  padding-top:70px;
}

.mt-6 {
  margin-top: 30px;
}

.mb-8 {
  margin-bottom: 50px;
}

.mb-6 {
  margin-bottom: 30px;
}

.ml-50{
  margin-left:50%;
}
.the_review{
  padding: 5px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 7px;
  margin-bottom:25px;
  padding:10px;
}
* {
  font-family: Arial, Helvetica, sans-serif;
  font-size: $font-size-normal;
}

.loading-wrap {
  text-align: center;
  margin-top: 10%;
}

.nav-container {
  border-bottom: 1px solid #ccc;
}

.bg-gray {
  background-color: #f3f4f6;
}

.bg-white {
  background-color: white;
}

.page {
  min-height: $page-min-height;
}

.page-header {
  &.style1 {
    background: #fff;
    text-align: center;
    padding: 80px 0px;
    margin-bottom: 30px;
    margin-top:16px;
  }

  h1 {
    font-size: $font-size-huge;
  }

  .subheading {
    font-size: $font-size-medium;
    margin-top: 10px
  }
}


.svcbx {
  padding: 40px 30px;
  border-radius: 8px;
  border: 1px solid #efefef;
  margin-bottom: 22px;
  text-align: center;

  &:hover {
    box-shadow: 0 3px 22px 0px rgb(0 0 0 / 20%);
  }

  .pic img {
    width: 60px;
    margin-bottom: 20px;
  }
}

.file-upload {
  .files {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        border-bottom: 1px solid #ccc;
        position: relative;
        padding: 9px 6px;
        margin-top: 5px;
        border-radius: 5px;

        &:hover {
          background-color: #fafafa;
        }

        .name {
          max-width: 280px;
          overflow: hidden;
          padding-left: 32px;
          display: inline-block;
        }

        .size {
          font-size: 11px;
          padding-right: 12px;

        }

        .idx {
          background: black;
          color: white;
          width: 20px;
          display: inline-block;
          text-align: center;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
          font-size: 10px;
          padding: 3px 3px;
          position: absolute;
          top: 12px;
          left: 8px;
        }

        .right {
          position: absolute;
          right: 12px;
        }

        .remove {
          cursor: pointer;
          color: red;

          &:hover {}
        }
      }
    }
  }

  .dropzone {
    border: 1px dashed #ccc;
    border-radius: 5px;
    padding: 30px 10px;
    text-align: center;
  }
}

.page-provider {

  .sidebar {
    margin-bottom: 48px;
    //   padding: 40px 20px;
    //   position: fixed;
    //   top: -1px;
    //   border: none;
    //   box-shadow: none;
    //   border: 1px solid #ccc;
    //   border-radius: 0;
    //   height: 100%;
    //   background: #fafafa;
    //   // margin-top: -51px;
    //   overflow-y: auto;
    //   // -ms-overflow-style: none;
    //   // scrollbar-width: none;
    //   // &::-webkit-scrollbar {
    //   //   display: none;
    //   // }
    //   // &:hover {
    //   // }
    //   textarea#jobDescription {
    //     height: 150px;
    //   }
  }

  .header {
    .logo {
      max-width: 150px;

      img {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .ratings {
      font-size: $font-size-normal;
    }

    .social {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 4px;

        li {
          display: inline-block;
          padding-right: 12px;

          svg {
            color: #333;
          }
        }
      }
    }
  }
}

h1 {
  font-size: $font-size-big;
  font-weight: bold;
}

h2 {
  font-size: $font-size-medium;
  font-weight: bold;
}


.anchorfy {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}




.scroll {
  scrollbar-color: var(--scroll-thumb-color, grey) var(--scroll-track, transparent);
  scrollbar-width: thin;
}

.scroll::-webkit-scrollbar {
  width: var(--scroll-size, 10px);
  height: var(--scroll-size, 10px);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, grey);
  background-image: var(--scroll-thumb, none);
  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
}

.scroll-1 {
  --scroll-size: 10px;
  --scroll-radius: 10px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb-color: #dfdfdf;
}

.scroll-2 {
  --scroll-size: 8px;
  --scroll-radius: 10px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb-color: rgb(0 0 0 / 80%);
}

.scroll-3 {
  --scroll-size: 5px;
  --scroll-radius: 10px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb-color: #fff;
}

.scroll-4 {
  --scroll-size: 12px;
  --scroll-radius: 20px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb: linear-gradient(45deg,
      rgba(255, 255, 255, 0.25) 20%, transparent 20%,
      transparent 40%, rgba(255, 255, 255, 0.25) 40%,
      rgba(255, 255, 255, 0.25) 60%, transparent 60%,
      transparent 80%, rgba(255, 255, 255, 0.25) 80%);
  --scroll-thumb-color: #f97316;
}

.scroll-5 {
  --scroll-size: 5px;
  --scroll-radius: 20px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb: linear-gradient(45deg, #00aeff, #a68eff);
}

.scroll-6 {
  --scroll-size: 12px;
  --scroll-radius: 20px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb: linear-gradient(rgba(255, 255, 255, 0.3) 20%, transparent 20%,
      transparent 40%, rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%, transparent 60%,
      transparent 80%, rgba(255, 255, 255, 0.3) 80%);
  --scroll-thumb-color: #10b981;
}



.uploaded-media {
  .media {
    display: inline-block;
    position: relative;

    img {
      margin: 10px 5px;
      border: 1px solid #ccc;
      border-radius: 7px;
      width: 80px;
      height: 80px;
    }

    a.delete {
      position: absolute;
      top: 28px;
      left: 20px;
      background: black;
      padding: 13px 20px;
      border-radius: 50%;
      color: white;
      opacity: 0.7;
      display: none;
      cursor: pointer;
    }

    &:hover a.delete {
      display: block;
    }
  }
}

// STYING FOR HOME PAGE | DEV-A

@font-face {
  font-family: Bebas Nueue;
  src: url('../public/fonts/Bebas_Neue/BebasNeue-Regular.ttf');
}

.page-body{

  margin-top:-20px;

}

.banner{

  min-height: 800px;

  background-image: url('../public/assets/banner1.jpg');

  background-repeat: no-repeat;

  background-size: cover;

  background-position: 100% 2%;

  clip-path: ellipse(93% 71% at 50% 28%);

}

.banner .banner-logo a {

  display: block;

  margin-top: 20px;

  margin-left: 0;

}

.banner-logo a img{

  margin-top:20px;

  margin-left:110px;

}

.inner-banner {

  min-height: 800px !important;

}

html body .banner .inner-banner .banner-content {

  text-align: center;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

  width: 70%;

  min-height: 400px;

  position: absolute;

  transform: translate(-50%,-50%);

  top: 85%;

  left: 50%;

}

.services {

  /* font-size: 3rem; */

  padding-top: 20px;

  padding-bottom: 20px;

}

.btn-request:hover {

  background: #2b68be;

  font-size: 20px;

  color: #fff;

  padding: 10px;

  margin-top: 20px;

  border-radius: 6px;

}

.banner .banner-content .banner-heading h1 {

  font-size: 50px;

  color: #007cba;

  font-weight: 700;

  letter-spacing: .2rem;

  font-weight: bolder;

  font-family: Bebas Nueue;

  margin-bottom:60px;

}

.search-field{

  padding-top:10px;

}

.banner .banner-content .banner-service p {

  font-size:18px;

  font-weight: 700;

  text-transform: uppercase;

  margin-bottom:40px;

}

html body .banner .inner-banner .banner-content .banner-service .blue-line {

  width: 5rem;

  height: 0.2rem;

  background-color: #007ac2;

  margin: 0 auto;

  margin-top: 3.5rem;

}

.banner .banner-content .banr-more-servc p {

  font-weight: 700;

  text-transform: uppercase;

  margin-top:10px;

  font-size:18px;

}

.banner .banner-content .search-field{

  height: 40px;

}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{

  --bs-gutter-x:0rem;

}

.btn-request {

  background: #2b68be;

  font-size: 20px;

  color: #fff;

  padding: 10px;

  border-radius: 6px;

  text-decoration: none;

}

.why-choose-burke {

  background-color: #f4911f;

  padding-top: 5rem;

  padding-bottom: 5rem;

  height: auto;

}

.why-choose-burke .inner-why-burke .why-brk-cont {

  color: #fff;

}

.why-choose-burke .inner-why-burke .why-brk-cont .why-burk-heading h2 {

  text-transform: uppercase;

  letter-spacing: 0.1rem;

  font-family: Bebas Nueue, cursive;

  font-size: 50px !important;

  margin-bottom:10px;

  text-align: center;

}

.why-burk-para p{

  padding: 20px 40px;

    font-weight: 600;

    text-align: center;

    color: #f9f9f9;

    font-size:16px;

}

.why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section {

  border-radius: 5rem;

  padding: 0 2rem 0;

  display: flex;

  margin-top:30px;

}

.why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section .more-div-img {

  height: auto;

}

.why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section .more-div-img img {

  width: 100%;

  /* display: block; */

  border-top-left-radius: 1rem;

  border-bottom-left-radius: 1rem;

  height: auto;

}

.why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul {

  /* width: 100%; */

  padding: 0rem 0rem 0rem 4rem;

  background-color: #fff;

  border-top-right-radius: 1rem;

  border-bottom-right-radius: 1rem;

  height: 100%;

  display: flex;

  flex-direction: column;

  justify-content: center;

  list-style-type: disc;

}

.why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul li {

  /* color: #007cba; */

  font-size: 1.8rem;

  text-decoration: none;

  color: #007cba;

  font-weight: 600;

  /* display: inline-block; */

  font-size: 25px;

  line-height: 54px;

  text-transform: uppercase;

  font-family: Bebas Nueue;

}

.bg-blue{

  background-color: #2b68be !important;

}

.bg-orange{
  background-color:#f4911f !important;
}


.request {

  padding: 30px;

  background: #007cba;

}

.border-r {

  border-right: 3px solid #fff;

}

.request .inner-contracor .contractor-content .contr-link a {

  text-decoration: none;

  color: #fff;

  font-size: 20px;

  text-transform: uppercase;

  font-weight: 600;

  text-align: center;

  display: block;

  line-height: 40px;

}

.mt-minus-25{
  margin-top:-25px;
}

.footer {

  padding: 40px;

  background-color: #d7d8d9;

}

.footer p{

  font-weight: bold;

}
/*
  media queries for devices
*/

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .request .inner-contracor .contractor-content .contr-link a{
    font-size:20px; 

  }
  .request .inner-contracor.border-r{
    border:0px;
  }

  .banner{
    background-position: 52% 2%;
    clip-path: ellipse(130% 71% at 50% 28%);
  }

  .request .inner-contracor .contractor-content .contr-link{
    margin-left: 50px !important;
  }
  .App .nav-container nav {
    margin-left:20px;
  }
  .App .nav-container nav{
    margin-right:20px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section .more-div-img img
  {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul{
    margin-top:15px;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .request .inner-contracor .contractor-content .contr-link a{
    width:300px;
  }
  .border-r{
    border:0px;
  }

  .request .inner-contracor .contractor-content .contr-link{
    margin-left:100px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul li{
    font-size:18px;
    line-height:35px;
  }

  .why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul{
    padding: 0rem 0rem 0rem 2rem;
  }

  .banner{
    background-position: 50% 2%;
  }

  html body .banner .inner-banner .banner-content{
    top:65%;
  }
  
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  html body .banner .inner-banner .banner-content{
    top:85%;
  }

  .banner{
    background-position: 40% 2%;
  }
  .why-choose-burke .inner-why-burke .why-brk-cont .more-divided-section ul li{
    font-size: 23px;
  }
}
@media screen and (max-width:991.98px) {

  .shorter {

    aspect-ratio: 1/1;

    width:100%;

    height:100%;

  }

}