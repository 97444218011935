.dynopanel {
  border: 1px solid #ccc;
  padding: 0 !important;
  border-radius: 16px;
  box-shadow: 0px 0px 44px -21px;
  /* margin-top: 80px; */
  overflow: hidden;
}



.dynopanel {

  .map {
    padding: 0;
    overflow: hidden;
    margin-top: -20px;

    &.shorter {}
  }

  background-color: white;
  ;

  .row {
    margin: 0;
  }

  .header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 15px;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #585858;
  }

  .list {
    margin-bottom: 30px;
  }

  .search {
    margin-bottom: 20px;
    padding-left: 15px;

    label {
      font-size: 12px;
      margin: 0;
    }
  }

  .total h3 {
    font-size: 13px;
    font-weight: 300;
    color: #585858;
    margin: 0;

    span.current-base-location {
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  img.icon-marker-base {
    width: 11px;
    margin: 0 2px;
  }


  .entries {
    max-height: 700px;
    overflow: auto;
    padding-right: 10px;
    margin-right: -15px;
    visibility: hidden;

    &:hover {
      visibility: visible;
    }

    .entry {
      margin-bottom: 10px;
      border-radius: 7px;
      box-shadow: 0 0 13px -10px;
      padding: 10px;
      padding-bottom: 14px;
      cursor: pointer;
      background: #f8f8ff;
      border: 1px solid #c8d1ff;
      visibility: visible;
      transition: background 1s;

      &:hover {
        border-width: 1px;
        background: #e6e6f6;
      }

      &.selected {
        border-width: 3px;
        padding: 8px;
        background: #f1f1f1;
      }

      .info {
        position: relative;
        font-size: 13px;
        line-height: 20px;

        h4 {
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: #444;
        }

        .distance {
          font-size: 9px;
          display: inline-block;
          padding: 0 3px;
          background: orange;
          line-height: normal;
          margin: 0 4px;
          border-radius: 3px;
          margin-bottom: 0;
        }

        .directions a.nav-link {
          display: inline-block;
        }

        a.phone {
          display: inline-block;
          color: #444;
          padding-right: 10px;
          text-decoration: none;
        }

        a.email {
          display: inline-block;
          color: #444;
          padding-right: 10px;
          text-decoration: none;
        }

      }

      .pic {
        display: table-cell;
        padding: 0;
        vertical-align: middle;

        img {
          width: 100%;
          margin: 0 auto;
          margin-top: 8px;
          /* border: 1px solid #eee; */
          border-radius: 5px;
        }

      }
    }


  } 

}

.href-back, .href {
  cursor: pointer;
}

.provider-profile {
  text-align: left;

  .logo img {
    width: 100%;
  }
}

.form-group.not-edit-mode {
  label {
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: #888;
}

  .input-value {
    margin-bottom: 30px;
  }
}



.info-window {
  color: #444;
  h4 {
    font-size: 18px;;
  }
  .fas {
    opacity: 0.7;
  }
  .logo {
    max-height: 60px;
  }
  a {
    color: #444;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}



